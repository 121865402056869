import type { ProjectMenuItemFragment } from '$gen/graphql';
import gql from 'graphql-tag';
import { seo } from '../../shared/fragments';

export const projectSlide = gql`
    fragment projectSlide on projectSlider_slide_BlockType {
        ... on projectSlider_slide_BlockType {
            __typename
            id
            description
            descriptionColor
            media {
                ... on studioShAssets_Asset {
                    id
                    __typename
                    id
                    url
                    kind
                    width
                    height
                    srcset(sizes: ["2560w", "2048w", "1680w", "1366w", "424w"])
                    title
                    embeddedAsset {
                        ... on EmbeddedAsset {
                            title
                            description
                            image
                            imageHeight
                            imageWidth
                        }
                        html
                    }
                }
            }
        }
    }
`;

export const project = gql`
    fragment project on projects_default_Entry {
        title
        id
        __typename
        uri
        projectSlider {
            ...projectSlide
        }
        mobileDescription
        seo {
            ...seo
        }
    }
    ${projectSlide}
    ${seo}
`;

export const projectMenuItem = gql`
    fragment projectMenuItem on projects_default_Entry {
        __typename
        id
        title
        uri
    }
`;

export const isProjectMenuItem = (item): item is ProjectMenuItemFragment => {
    return (item as ProjectMenuItemFragment).__typename === 'projects_default_Entry';
};
