<script lang="ts">
    import KubaparisSshToggle from './KubaparisSshToggle.svelte';
    import type { StudioShMenuQuery } from '$gen/graphql';
    import { routes } from '$lib/routes';
    import { page } from '$app/stores';
    import { afterNavigate } from '$app/navigation';

    type Project = Extract<
        StudioShMenuQuery['projectTitles'][number],
        { __typename: 'projects_default_Entry' }
    >;

    export let content: StudioShMenuQuery;

    let showProjectList = true;

    $: projects = content?.projectTitles ? (content.projectTitles as Project[]) : [];
    $: menu =
        content && content.menu && content.menu.__typename === 'studioShMenu_GlobalSet'
            ? content.menu
            : null;

    afterNavigate(({ from, to }) => {
        /** After navigating from a page other than the project route open the projects menu. */
        if (
            !from?.pathname.startsWith(routes.ssh_projects) &&
            to.pathname.startsWith(routes.ssh_projects)
        ) {
            showProjectList = true;
        }
    });
</script>

{#if content}
    <nav class="grid w-full grid-cols-gridWithAside pt-5">
        <ul id="main-menu" class="flex max-w-full items-baseline space-x-1">
            <KubaparisSshToggle />
            <ul class="flex w-full justify-between space-x-2 text-xl leading-5 text-white">
                {#if projects && projects.length > 0}
                    <li class="relative -ml-2">
                        <!-- When on project page use the button as toggle to open and close the project list. -->
                        {#if $page.url.pathname.startsWith(routes.ssh_projects)}
                            <button
                                class:italic={$page.url.pathname.startsWith(routes.ssh_projects)}
                                on:click={() => (showProjectList = !showProjectList)}
                                class="px-2 leading-normal"
                            >
                                Projects
                            </button>
                        {:else}
                            <a
                                sveltekit:prefetch
                                class="cursor-pointer hover:text-blue"
                                href={`/${projects[0]?.uri}`}
                            >
                                Projects
                            </a>
                        {/if}
                        {#if $page.url.pathname.startsWith(routes.ssh_projects)}
                            <ul
                                class="absolute top-7 left-0 ml-2 mt-1 flex w-max max-w-[20ch] flex-col gap-1 lg:mt-1 lg:max-w-none lg:gap-0"
                            >
                                {#each projects as project, index}
                                    <li
                                        style:transition-delay={`${index * 30}ms`}
                                        class:italic={$page.url.pathname === `/${project.uri}`}
                                        class={`${
                                            showProjectList ? 'opacity-100' : 'opacity-0'
                                        } transition-opacity hover:text-blue`}
                                    >
                                        <a
                                            href={`/${project.uri}`}
                                            sveltekit:prefetch
                                            on:click={() => (showProjectList = false)}
                                        >
                                            {project.title}
                                        </a>
                                    </li>
                                {/each}
                            </ul>
                        {/if}
                    </li>
                {/if}

                {#each menu.menuSubpages as subpage}
                    <li
                        class="hover:text-blue"
                        class:italic={$page.url.pathname === `/${subpage.subpage[0].uri}`}
                    >
                        <a class="" href={`/${subpage.subpage[0].uri}`} sveltekit:prefetch>
                            {subpage.label}
                        </a>
                    </li>
                {/each}
                <ul id="links" class="flex gap-1 ">
                    {#each menu.socialMediaLinks as { link, label }, index}
                        <li>
                            <a href={link} target="_blank" rel="external" class="hover:text-blue"
                                >{label}{index + 1 === menu.socialMediaLinks.length ? '' : ','}
                            </a>
                        </li>
                    {/each}
                </ul>
            </ul>
        </ul>
    </nav>
{/if}

<style>
    #main-menu {
        grid-column: main;
    }

    li,
    button {
        text-shadow: 0 0 8px theme('colors.black');
    }
</style>
