<script context="module" lang="ts">
    import type { Load } from '@sveltejs/kit';
    import { isProjectMenuItem } from '$lib/api/studio-sh/fragments';

    export const load: Load = async ({ stuff }) => {
        const query = await stuff.cms.studioShMenu();
        const projects = query?.projectTitles ? query.projectTitles.filter(isProjectMenuItem) : [];

        return {
            stuff: { projects },
            props: { query },
        };
    };
</script>

<script lang="ts">
    import PreloadingIndicator from '$lib/components/nav/PreloadingIndicator.svelte';
    import { navigating } from '$app/stores';
    import StudioShMenu from '$lib/components/nav/StudioShMenu.svelte';
    import type { StudioShMenuQuery } from '$gen/graphql';
    import StudioShMobileMenu from '$lib/components/nav/StudioShMobileMenu.svelte';
    import { routes } from '$lib/routes';

    export let query: StudioShMenuQuery;

    const footerItems = [
        { label: 'Imprint', href: routes.imprint },
        { label: 'Privacy Policy', href: routes.privacyPolicy },
    ];
</script>

{#if $navigating}
    <PreloadingIndicator />
{/if}

<header class="hidden lg:block">
    <StudioShMenu content={query} />
</header>

<StudioShMobileMenu content={query} />

<main class="h-full overflow-hidden bg-black text-white">
    <slot />
</main>

<footer class="fixed bottom-2 right-3 hidden text-xs lg:block">
    <ul class="flex gap-2">
        {#each footerItems as { label, href }}
            <li><a class="text-shadow-white" {href} sveltekit:prefetch>{label}</a></li>
        {/each}
    </ul>
</footer>

<style lang="scss">
    header {
        @apply z-desktop-menu;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        nav {
            padding: var(--gap);

            & > ul {
                display: flex;
                flex-direction: row;
            }
        }
    }

    main {
        & > :global(*) {
            grid-column: main;
        }
    }
</style>
