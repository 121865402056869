<script lang="ts">
    import { routes } from '$lib/routes';
    import { page } from '$app/stores';
    import type { StudioShMenuQuery } from '$gen/graphql';
    import { afterNavigate } from '$app/navigation';
    import BurgerIcon from './BurgerIcon.svelte';
    import KubaparisSshMobileToggle from './KubaparisSshMobileToggle.svelte';
    import { browser } from '$app/env';

    type Project = Extract<
        StudioShMenuQuery['projectTitles'][number],
        { __typename: 'projects_default_Entry' }
    >;

    export let content: StudioShMenuQuery;

    $: projects = content?.projectTitles ? (content.projectTitles as Project[]) : [];
    $: menu =
        content && content.menu && content.menu.__typename === 'studioShMenu_GlobalSet'
            ? content.menu
            : null;

    $: footer = content?.footer?.__typename === 'footer_GlobalSet' ? content.footer : null;

    let open = false;
    $: if (browser) {
        open === true ? fixBody() : unfixBody();
    }

    afterNavigate(() => {
        // Close the menu after navigating is done.
        open = false;
    });

    function fixBody() {
        // When the modal is shown, we want a fixed body
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
    }
    function unfixBody() {
        // When the modal is hidden...
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
</script>

<header
    class="fixed top-0 left-0 z-desktop-menu max-h-full w-screen overflow-y-auto lg:hidden"
    style:height={open ? '100%' : 'auto'}
    class:bg-yellow={open}
>
    {#if open}
        <nav class="relative flex h-full flex-col justify-between p-5 text-black">
            {#if content}
                <ul id="main-menu" class="flex-grow text-3xl">
                    <li>
                        <a
                            href={routes.kubaparis}
                            sveltekit:prefetch
                            class:italic={$page.url.pathname === routes.kubaparis}
                        >
                            KUBAPARIS
                        </a>
                    </li>
                    <li>
                        <a
                            href={routes.ssh_projects}
                            sveltekit:prefetch
                            class:italic={$page.url.pathname === routes.ssh}
                        >
                            Studio SH
                        </a>
                    </li>
                    <!-- Projects -->
                    <li>
                        <a
                            href={routes.ssh_projects}
                            sveltekit:prefetch
                            class:italic={$page.url.pathname === routes.ssh_projects}
                        >
                            Projects
                        </a>
                        <ul>
                            {#each projects as { uri, title, id } (id)}
                                {@const path = `/${uri}`}
                                <li>
                                    <a
                                        href={path}
                                        class:italic={$page.url.pathname === path}
                                        sveltekit:prefetch
                                        class=""
                                    >
                                        {title}
                                    </a>
                                </li>
                            {/each}
                        </ul>
                    </li>
                    <!-- Subpage links (internal) -->
                    {#each menu.menuSubpages as subpage (subpage.id)}
                        {@const subpagePath = `/${subpage.subpage[0].uri}`}
                        <li>
                            <a
                                class:italic={$page.url.pathname === subpagePath}
                                class=""
                                href={subpagePath}
                                sveltekit:prefetch
                            >
                                {subpage.label}
                            </a>
                        </li>
                    {/each}
                </ul>

                <!-- Sozial media links (external) -->
                <div class="my-10 grid place-content-center">
                    <ul id="social-media" class="flex flex-wrap justify-center gap-x-2 text-xl">
                        {#each menu.socialMediaLinks as { link, label }}
                            <li class="inline-block">
                                <a href={link} target="_blank" rel="external">{label}</a>
                            </li>
                        {/each}
                    </ul>
                </div>

                <!-- Footer -->
                <div class="flex flex-wrap justify-center gap-x-2 text-xl">
                    <ul id="internal-links" class=" flex flex-row space-x-2">
                        {#if footer}
                            {#each footer.linkToSingles as link}
                                <li>
                                    <a href={`/${link.uri}`} sveltekit:prefetch>{link.title}</a>
                                </li>
                            {/each}
                        {/if}
                    </ul>
                </div>
            {/if}
        </nav>
    {:else}
        <KubaparisSshMobileToggle />
    {/if}
    <BurgerIcon bind:open whiteStroke={open ? false : true} />
</header>

<style>
    #main-menu {
        grid-column: main;
    }

    #main-menu > li > a::after,
    #social-media li > a::after {
        content: '';
    }

    li:last-child > a::after {
        content: none !important;
    }
</style>
